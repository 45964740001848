<template>
  <div class="invoices">
    <div v-if="isLoadingInvoices">
      <v-skeleton-loader
        data-test="loader"
        v-for="i in 10"
        :key="i"
        type="list-item-avatar"
      />
    </div>
    <div v-else>
      <div
        class="invoices__list"
        v-for="set in groupedInvoiceBySet"
        :key="set.id"
      >
        <div v-if="set.id !== 0" class="d-flex justify-space-between mb-3">
          <h1>
            {{ `${set.id} ${set.client.name}` }}, to
            {{ set.end_date | fullDate }}
          </h1>
          <div width="20px" class="mr-5">
            <v-img
              @click="downloadInvoiceSet(set)"
              class="download__icon"
              height="20px"
              contain
              src="@/assets/svg/download.svg"
            />
          </div>
        </div>
        <v-list-item v-for="invoice in set.invoices" :key="invoice.id">
          <InvoiceListItem :invoice="invoice" />
        </v-list-item>
        <hr class="mt-3 mb-4" />
      </div>
      <v-pagination
        :total-visible="page.pageSize"
        v-if="showPagination"
        v-model="page.currentPage"
        @input="fetchInvoiceData"
        :length="page.lastPage"
      />
    </div>
    <BaseFooter v-if="isPermissioned('finance.generate-invoices')">
      <div class="actions-bar">
        <GenerateInvoiceAction />
      </div>
    </BaseFooter>
  </div>
</template>

<script>
import { sortTableItems } from "@/utils/helpers";
import { GET_ALL_INVOICES } from "@/store/modules/invoices/action-types";
import { createNamespacedHelpers } from "vuex";
import { INVOICE_NAMESPACE } from "@/store/modules/invoices";
import InvoiceListItem from "@/views/finance/components/Invoices/components/InvoiceListItem";
import generateId from "uuid/v4";
import { SET_INVOICE_FILTERS } from "@/store/modules/invoices/mutation-types";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import BaseFooter from "@/components/common/BaseFooter";
import GenerateInvoiceAction from "@/views/finance/components/Invoices/components/actions/GenerateInvoiceAction";
import { isPermissioned } from "@/utils/permissions";
import { groupInvoiceBySet } from "@/utils/invoices";
import { forceFileDownload } from "@/utils/files";
import APIService from "@/services/APIService";
import { getToken } from "@/services/utils";
import { paginationMixin } from "@/mixins/pagination.mixin";

const { mapState: authMapState } = createNamespacedHelpers(AUTH_NAMESPACE);
const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
  INVOICE_NAMESPACE
);

export default {
  name: "Invoices",
  components: {
    InvoiceListItem,
    BaseFooter,
    GenerateInvoiceAction
  },
  mixins: [paginationMixin],
  props: {
    search: String
  },
  created() {
    this.page = {
      pageSize: 20,
      currentPage: 1,
      lastPage: 1
    };
    this.fetchInvoiceData();
  },
  data: () => ({
    isGenerateInvoicesOpen: false,
    filtersId: generateId(),
    availableFilters: {},
    today: new Date()
  }),
  mounted() {},
  computed: {
    ...mapState({
      invoices: state => state.invoices,
      isLoadingInvoices: state => state.isFetchingInvoices,
      invoiceFilters: state => state.invoiceFilters
    }),
    ...authMapState(["permissions"]),
    groupedInvoiceBySet() {
      return groupInvoiceBySet(this.invoices);
    },
    filterCount() {
      return Object.keys(this.invoiceFilters).length;
    },
    showGenerateButton() {
      return this.permissions["finance.generate-invoices"];
    },
    requestParams() {
      return {
        filter: this.invoiceFilters,
        ...this.requestPageParams,
        sort: "-createdAt",
        include: ""
      };
    }
  },
  methods: {
    ...mapActions({
      getAllInvoices: GET_ALL_INVOICES
    }),
    ...mapMutations({
      setInvoiceFilters: SET_INVOICE_FILTERS
    }),
    isPermissioned,
    customSort(items, arr, isDescArray) {
      const options = { dateValuesLabels: ["date"] };
      return sortTableItems(items, arr, isDescArray, options);
    },
    updateFilters(filters) {
      this.setInvoiceFilters(filters);
      this.fetchInvoiceData();
    },
    clearFilters() {
      this.setInvoiceFilters({});
      this.fetchInvoiceData();
    },
    openDialog() {
      this.$emit("openDialog", "GenerateInvoices");
    },
    downloadInvoiceSet(set) {
      const token = `token=${getToken()}`;
      const url = `${APIService.host}/client-invoice-sets/${set.id}/zip?${token}`;
      forceFileDownload(url);
    },
    async fetchInvoiceData() {
      try {
        const { meta, data } = await this.getAllInvoices(this.requestParams);
        this.availableFilters = meta.filters;

        if (data) {
          if (meta) {
            this.updatePageDetails(meta);
          }
        }
      } catch (error) {
        console.error("Error fetching invoice data:", error);
      }
    }
  },
  watch: {
    search(newValue) {
      this.updateFilters({
        ...this.invoiceFilters,
        search: newValue
      });
    }
  }
};
</script>

<style lang="scss">
.invoices {
  hr {
    border-top: 1px solid $secondary-line;
  }
  &__list {
    & > h1,
    & > hr {
      margin: 0 16px;
    }
  }
}
.actions-bar {
  margin-top: 35px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
